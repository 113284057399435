import { getLodop } from "@/utils/LodopFuncs";

export default function printOrder(items) {
  const LODOP = getLodop();
  LODOP.PRINT_INIT("采购合同");
  LODOP.SET_PRINT_PAGESIZE(1, "210mm", "297mm");
  LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW", true);
  LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);

  for (const item of items) {
    const tableRows = item.purchase_goods_items.map((item, index) => {
      return `
        <tr style="text-align: center">
          <td>${index + 1}</td>
          <td>${item.goods_name ?? ""}</td>
          <td>${item.goods_spec ?? ""}</td>
          <td>${item.purchase_quantity ?? 0}</td>
          <td>${item.purchase_price.toFixed(2) ?? "0.00"}</td>
          <td>¥${item.total_amount ?? "0.00"}</td>
          <td>${item.delivery_date ?? ""}</td>
        </tr>
      `;
    });

    const pageContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            td {
              border: 1px solid black;
              padding: 4px 8px;
            }
          </style>
        </head>
      
        <body>
          <div style="width: 210mm; height: 297mm; position: relative">
            <div style="padding: 16px 16px 0 16px; height: 100%">
              <div style="text-align: center; font-weight: bold">
                <div style="font-size: 36px">采购合同</div>
                <div style="font-size: 18px">QR-001-1</div>
              </div>
      
              <div style="text-align: right; font-size: 18px; font-weight: bold">NO: ${item.order_number ?? ""}</div>
      
              <div style="font-size: 16px">
                <table cellspacing="0" style="border: 2px solid black; border-collapse: collapse; width: 100%">
                  <tr>
                    <td colspan="3">供应商: ${item.supplier_name ?? ""}</td>
                    <td colspan="4">制单dan日期: ${item.handle_time ?? ""}</td>
                  </tr>
                  <tr>
                    <td colspan="3">联系人: ${item.contacts ?? ""}</td>
                    <td colspan="4">地址: ${item.supplier_address ?? ""}</td>
                  </tr>
                  <tr>
                    <td colspan="3">联系人电话: ${item.contact_phone ?? ""}</td>
                    <td colspan="4">备注: ${item.remark ?? ""}</td>
                  </tr>
                  <tr style="text-align: center">
                    <td style="width: 8%">项次</td>
                    <td style="width: 21%">物料名称</td>
                    <td style="width: 21%">型号规格</td>
                    <td style="width: 10%">数量</td>
                    <td style="width: 10%">单价</td>
                    <td style="width: 15%">总金额</td>
                    <td style="width: 15%">交货日期</td>
                  </tr>
                  ${tableRows.join("")}
                  <tr style="height: 29px">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr style="height: 29px">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr style="height: 29px">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr style="height: 29px">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr style="height: 29px">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colspan="3">下单人: ${item.handler_name}</td>
                    <td colspan="4" style="text-align: center; vertical-align: bottom">供应商确认(请签字盖章后回传)</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </body>
      </html>    
`;

    LODOP.NewPage();
    LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", pageContent);
  }

  LODOP.PREVIEW();
}

function numberToChinese(num) {
  const fraction = ["角", "分"];
  const digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
  const unit = [
    ["元", "万", "亿"],
    ["", "拾", "佰", "仟"],
  ];
  let head = num < 0 ? "欠" : "";
  num = Math.abs(num);

  let s = "";

  for (let i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(num * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, "");
  }
  s = s || "整";
  num = Math.floor(num);

  for (let i = 0; i < unit[0].length && num > 0; i++) {
    let p = "";
    for (let j = 0; j < unit[1].length && num > 0; j++) {
      p = digit[num % 10] + unit[1][j] + p;
      num = Math.floor(num / 10);
    }
    s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
  }

  return (
    head +
    s
      .replace(/(零.)*零元/, "元")
      .replace(/(零.)+/g, "零")
      .replace(/^整$/, "零元整")
  );
}
